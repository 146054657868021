"use client";

import { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Skeleton,
  Box,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import FilterListIcon from "@mui/icons-material/FilterList";
import axios from "axios";
import SubCard from "../../ui-component/cards/SubCard";
import { numericCommaSeparation } from "../../customFunctions/numericCommaSeparation";
// Static data for each region

const SkeletonBox = () => {
  return (
    <Box>
      <Skeleton height={90} width={"100%"} animation="wave" />
      <Skeleton height={90} width={"100%"} animation="wave" />
      <Skeleton height={90} width={"100%"} animation="wave" />
      <Skeleton height={90} width={"100%"} animation="wave" />
      <Skeleton height={90} width={"100%"} animation="wave" />
    </Box>
  );
};
export default function RegionWIseData() {
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [withoutLorealData, setWithoutLorealData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [zoneWiseData, setZonewiseData] = useState({
    east: [],
    north: [],
    south: [],
    west: [],
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  function aggregateAmounts(arr) {
    return Object.values(arr.reduce((acc, item) => {
        if (!acc[item.City]) {
            acc[item.City] = { City: item.City, TotalPayableAmount: 0,State:item.State ,NumericOrderId:item.NumericOrderId};
        }
        acc[item.City].TotalPayableAmount += item.TotalPayableAmount;
        return acc;
    }, {}));
}


const filterByZone = (region,data)=>{
  return data.filter((item)=>(item.ZONE==region&&item.NumericOrderId!=2964781))
}

  // Filter table data when a region is selected
  const handleRegionClick = (region) => {
    if(loading) return
    if(selectedRegion==null){
      setSelectedRegion(region);
      let zoneData = filterByZone(region,withoutLorealData)
      let groupedData = aggregateAmounts(zoneData)

      setFilteredData(groupedData.sort((a,b)=>b.TotalPayableAmount-a.TotalPayableAmount));
    }else{
      showAllData()
    }
  };

  // Reset filters to show all data
  const showAllData = () => {
    setSelectedRegion(null);
    let groupedData = aggregateAmounts(withoutLorealData)
    setFilteredData(groupedData.sort((a,b)=>b.TotalPayableAmount-a.TotalPayableAmount));
  };

  const groupByState = (data) => {
    const result = {};
    Object.entries(data).forEach(([zone, records]) => {
      result[zone.toLowerCase().replace(" zone", "")] = Object.entries(
        records.reduce((acc, { state, amount }) => {
          acc[state] = (acc[state] || 0) + amount;
          return acc;
        }, {})
      ).map(([state, value]) => ({ state, value }));
    });
    return result;
  };

  const getData = () => {
    axios
      .get('https://prelive.buyume.io' + "/testing/getLorealZoneWiseData")
      .then((res) => {
        if (res.data.success) {
          filterData(res.data.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const sortByAmount = (data) => {
    Object.keys(data).forEach((zone) => {
      data[zone] = data[zone].sort((a, b) => b.value - a.value).slice(0, 10);
    });
    return data;
  };

  function filterData(data) {
    const zones = {
      "South Zone": [],
      "North Zone": [],
      "East Zone": [],
      "West Zone": [],
    };
    let filterLorealData = data.filter((item) => (item.Brand == "Loreal"&&item.NumericOrderId!=2964781));
    let groupedData = aggregateAmounts(filterLorealData)
    setWithoutLorealData(filterLorealData);
    setFilteredData(groupedData.sort((a,b)=>b.TotalPayableAmount-a.TotalPayableAmount));
    filterLorealData.forEach((item) => {
      if (zones[item.ZONE]) {
        zones[item.ZONE].push({
          state: item["FState"],
          amount: item["TotalPayableAmount"],
        });
      }
    });
    let stateWiseData = groupByState(zones);
    const sortData = sortByAmount(stateWiseData);
    setZonewiseData(sortData);
    setLoading(false)
  }

  useEffect(() => {
    getData();
    // filterData()
  }, []);

  return (
    <SubCard className="p-4">
      <Typography variant="h4" component="h1" gutterBottom>
        State wise report
      </Typography>

      <Grid container spacing={3}>
        {/* South Data Card */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              cursor: "pointer",
              border:
                selectedRegion === "South Zone" ? "2px solid #2196f3" : "none",
            }}
            onClick={() => handleRegionClick("South Zone")}
            
          >
            <div className="flex justify-between items-center mb-2">
              <Typography
                variant="h6"
                color="#4fc3f7"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <SouthIcon sx={{ mr: 1 }} /> South Zone
              </Typography>
              {/* <Button variant="outlined" size="small" sx={{ color: "#4fc3f7", borderColor: "#4fc3f7" }}>
                Show Data
              </Button> */}
            </div>
            <ResponsiveContainer width="100%" height={450}>
              {loading ? (
                <SkeletonBox />
              ) : (
                <BarChart
                  layout="vertical"
                  data={zoneWiseData.south}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  {/* <CartesianGrid strokeDasharray="3 3" /> */}
                  <XAxis type="number" />
                  <YAxis dataKey="state" type="category" />
                  <Tooltip />
                  <Bar dataKey="value" fill="#80deea" />
                </BarChart>
              )}
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* North Data Card */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              cursor: "pointer",
              border:
                selectedRegion === "North Zone" ? "2px solid #2196f3" : "none",
            }}
            onClick={() => handleRegionClick("North Zone")}
          >
            <div className="flex justify-between items-center mb-2">
              <Typography
                variant="h6"
                color="#4fc3f7"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <NorthIcon sx={{ mr: 1 }} /> North Zone
              </Typography>
              {/* <Button variant="outlined" size="small" sx={{ color: "#4fc3f7", borderColor: "#4fc3f7" }}>
                Show Data
              </Button> */}
            </div>
            <ResponsiveContainer width="100%" height={450}>
              {loading ? (
                <SkeletonBox />
              ) : (
                <BarChart
                  layout="vertical"
                  data={zoneWiseData.north}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  {/* <CartesianGrid strokeDasharray="3 3" /> */}
                  <XAxis type="number" />
                  <YAxis dataKey="state" type="category" />
                  <Tooltip />
                  <Bar dataKey="value" fill="#80deea" />
                </BarChart>
              )}
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* West Data Card */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              cursor: "pointer",
              border:
                selectedRegion === "West Zone" ? "2px solid #2196f3" : "none",
            }}
            onClick={() => handleRegionClick("West Zone")}
          >
            <div className="flex justify-between items-center mb-2">
              <Typography
                variant="h6"
                color="#4fc3f7"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <WestIcon sx={{ mr: 1 }} /> West Zone
              </Typography>
              {/* <Button variant="outlined" size="small" sx={{ color: "#4fc3f7", borderColor: "#4fc3f7" }}>
                Show Data
              </Button> */}
            </div>
            <ResponsiveContainer width="100%" height={450}>
              {loading ? (
                <SkeletonBox />
              ) : (
                <BarChart
                  layout="vertical"
                  data={zoneWiseData.west}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  {/* <CartesianGrid strokeDasharray="3 3" /> */}
                  <XAxis type="number" />
                  <YAxis dataKey="state" type="category" />
                  <Tooltip />
                  <Bar dataKey="value" fill="#80deea" />
                </BarChart>
              )}
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* East Data Card */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              cursor: "pointer",
              border:
                selectedRegion === "East Zone" ? "2px solid #2196f3" : "none",
            }}
            onClick={() => handleRegionClick("East Zone")}
          >
            <div className="flex justify-between items-center mb-2">
              <Typography
                variant="h6"
                color="#4fc3f7"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <EastIcon sx={{ mr: 1 }} /> East Zone
              </Typography>
              {/* <Button variant="outlined" size="small" sx={{ color: "#4fc3f7", borderColor: "#4fc3f7" }}>
                Show Data
              </Button> */}
            </div>
            <ResponsiveContainer width="100%" height={450}>
              {loading ? (
                <SkeletonBox />
              ) : (
                <BarChart
                  layout="vertical"
                  data={zoneWiseData.east}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  {/* <CartesianGrid strokeDasharray="3 3" /> */}
                  <XAxis type="number" />
                  <YAxis dataKey="state" type="category" />
                  <Tooltip />
                  <Bar dataKey="value" fill="#80deea" />
                </BarChart>
              )}
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>

      {/* Data Table */}
      <Paper elevation={3} sx={{ mt: 3, p: 2 }}>
        <div className="flex justify-between items-center mb-3">
          <Typography variant="h6">
            {selectedRegion
              ? `${selectedRegion} Region Data`
              : "All Regions Data"}
          </Typography>
          {selectedRegion && (
            <Button
              variant="outlined"
              startIcon={<FilterListIcon />}
              onClick={showAllData}
            >
              Show All Data
            </Button>
          )}
        </div>
        <TableContainer sx={{ maxHeight: "80vh" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>cluster</TableCell>

                <TableCell>State</TableCell>
                <TableCell>Total payable amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData &&
                filteredData.length > 0 &&
                filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((order) => (
                    <TableRow key={order.id}>
                      <TableCell> <p style={{ textTransform: "capitalize" }}>{order.City}</p></TableCell>
                      <TableCell> <p style={{ textTransform: "capitalize" }}>{order.State}</p></TableCell>
                      <TableCell>{numericCommaSeparation(order?.TotalPayableAmount||0)}</TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 250, 1000]}
          component="div"
          count={filteredData.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </SubCard>
  );
}
