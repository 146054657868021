// third-party
import { FormattedMessage } from 'react-intl';
import Groups2Icon from '@mui/icons-material/Groups2';

// assets
import WarehouseIcon from '@mui/icons-material/Warehouse';
import BugReportIcon from '@mui/icons-material/BugReport';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { IconChartArcs, IconListCheck, IconUsers, IconClipboardList, IconChartInfographic, IconDashboard, IconHome2, IconCalendarTime } from '@tabler/icons';

// constant
const icons = {
    IconChartArcs: IconChartArcs,
    IconListCheck: IconListCheck,
    IconUsers: IconUsers,
    IconClipboardList: IconClipboardList,
    IconChartInfographic: IconChartInfographic,
    IconDashboard: IconDashboard,
    IconHome2: IconHome2,
    IconCalendarTime: IconCalendarTime
};
//-----------------------|| WIDGET MENU ITEMS ||-----------------------//

export const LorealBrand = {
    id: 'Reports',
    title: <FormattedMessage id="Reports" />,
    type: 'group',
    children: [
        {
            id: 'Cluster wise report',
            title: <FormattedMessage id="Cluster wise report" />,
            type: 'item',
            url: '/clusterwisereport',
            icon: IconListCheck,
            breadcrumbs: false,
        },
        {
            id: 'Region Wise Data',
            title: <FormattedMessage id="Region Wise Data" />,
            type: 'item',
            url: '/regionwisedata',
            icon: IconListCheck,
            breadcrumbs: false,
        },
        {
            id: 'Product Wise Data',
            title: <FormattedMessage id="Product Wise Data" />,
            type: 'item',
            url: '/productwisereport',
            icon: IconListCheck,
            breadcrumbs: false,
        },


    ]
}