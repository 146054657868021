import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { TablePagination } from "@mui/material";
import SubCard from "../../ui-component/cards/SubCard";

function LorealCityWiseData() {
  //   const [orderList, setOrderList] = useState([
  //     {
  //       city: "Agra",
  //       state: "Uttar Pradesh",
  //       Sum_of_price: "33,40,728",
  //       Count_Of_Buyer: 301
  //     },
  //     {
  //       city: "Jaipur",
  //       state: "Rajasthan",
  //       Sum_of_price: "25,68,798",
  //       Count_Of_Buyer: 187
  //     },
  //     {
  //       city: "Surat",
  //       state: "Gujarat",
  //       Sum_of_price: "28,10,750",
  //       Count_Of_Buyer: 195
  //     },
  //     {
  //       city: "Lucknow",
  //       state: "Uttar Pradesh",
  //       Sum_of_price: "31,39,483",
  //       Count_Of_Buyer: 281
  //     },
  //     {
  //       city: "Patna",
  //       state: "Bihar",
  //       Sum_of_price: "31,81,964",
  //       Count_Of_Buyer: 294
  //     },
  //     {
  //       city: "Ahmedabad",
  //       state: "Gujarat",
  //       Sum_of_price: "10,23,760",
  //       Count_Of_Buyer: 139
  //     },
  //     {
  //       city: "Kota",
  //       state: "Rajasthan",
  //       Sum_of_price: "24,32,453",
  //       Count_Of_Buyer: 216
  //     },
  //     {
  //       city: "Bikaner",
  //       state: "Rajasthan",
  //       Sum_of_price: "19,40,958",
  //       Count_Of_Buyer: 203
  //     }
  //   ]);

  const orderList = [
    {
      city: "LUCKNOW",
      State: "UTTAR PRADESH",
      Sum_of_price: 2265067,
      Count_Of_Buyer: 91,
    },
    {
      city: "PATNA",
      State: "BIHAR",
      Sum_of_price: 1001031,
      Count_Of_Buyer: 62,
    },
    {
      city: "MEERUT",
      State: "UTTAR PRADESH",
      Sum_of_price: 804538,
      Count_Of_Buyer: 61,
    },
    {
      city: "Banglore",
      State: "KARNATAKA",
      Sum_of_price: 928886,
      Count_Of_Buyer: 56,
    },
    {
      city: "JAIPUR",
      State: "Rajasthan",
      Sum_of_price: 606484,
      Count_Of_Buyer: 56,
    },
    {
      city: "South Chotanagpur",
      State: "JHARKHAND",
      Sum_of_price: 812290,
      Count_Of_Buyer: 55,
    },
    {
      city: "BHOPAL",
      State: "MADHYA PRADESH",
      Sum_of_price: 1231551,
      Count_Of_Buyer: 53,
    },
    {
      city: "AHMEDABAD",
      State: "Gujarat",
      Sum_of_price: 754621,
      Count_Of_Buyer: 47,
    },
    {
      city: "Gorakhpur",
      State: "UTTAR PRADESH",
      Sum_of_price: 712637,
      Count_Of_Buyer: 46,
    },
    {
      city: "KOTA",
      State: "Rajasthan",
      Sum_of_price: 638540,
      Count_Of_Buyer: 46,
    },
    {
      city: "BAREILLY",
      State: "UTTAR PRADESH",
      Sum_of_price: 533770,
      Count_Of_Buyer: 38,
    },
    {
      city: "UDAIPUR",
      State: "Rajasthan",
      Sum_of_price: 480798,
      Count_Of_Buyer: 36,
    },
    {
      city: "HOWRAH",
      State: "WEST BENGAL",
      Sum_of_price: 380062,
      Count_Of_Buyer: 35,
    },
    {
      city: "Surat",
      State: "Gujarat",
      Sum_of_price: 259382,
      Count_Of_Buyer: 33,
    },
    {
      city: "Palwal",
      State: "HARYANA",
      Sum_of_price: 174570,
      Count_Of_Buyer: 33,
    },
    {
      city: "BARDHAMAN",
      State: "WEST BENGAL",
      Sum_of_price: 466677,
      Count_Of_Buyer: 30,
    },
    {
      city: "North Chotanagpur",
      State: "JHARKHAND",
      Sum_of_price: 278456,
      Count_Of_Buyer: 30,
    },
    {
      city: "KANPUR NAGAR",
      State: "UTTAR PRADESH",
      Sum_of_price: 242701,
      Count_Of_Buyer: 29,
    },
    {
      city: "Zone-8",
      State: "TAMIL NADU",
      Sum_of_price: 558797,
      Count_Of_Buyer: 28,
    },
    {
      city: "REWA",
      State: "MADHYA PRADESH",
      Sum_of_price: 315031,
      Count_Of_Buyer: 28,
    },
    {
      city: "Amreli",
      State: "Gujarat",
      Sum_of_price: 146993,
      Count_Of_Buyer: 28,
    },
    {
      city: "JABALPUR",
      State: "MADHYA PRADESH",
      Sum_of_price: 600562,
      Count_Of_Buyer: 27,
    },
    {
      city: "VARANASI",
      State: "UTTAR PRADESH",
      Sum_of_price: 401486,
      Count_Of_Buyer: 27,
    },
    {
      city: "AJMER",
      State: "Rajasthan",
      Sum_of_price: 331748,
      Count_Of_Buyer: 26,
    },
    {
      city: "AGRA",
      State: "UTTAR PRADESH",
      Sum_of_price: 732377,
      Count_Of_Buyer: 25,
    },
    {
      city: "SOUTH EAST DELHI",
      State: "DELHI",
      Sum_of_price: 158933,
      Count_Of_Buyer: 25,
    },
    {
      city: "BIKANER",
      State: "Rajasthan",
      Sum_of_price: 953318,
      Count_Of_Buyer: 24,
    },
    {
      city: "Bhagalpur",
      State: "BIHAR",
      Sum_of_price: 429546,
      Count_Of_Buyer: 24,
    },
    {
      city: "ALWAR",
      State: "Rajasthan",
      Sum_of_price: 195705,
      Count_Of_Buyer: 24,
    },
    {
      city: "Muzaffarpur",
      State: "BIHAR",
      Sum_of_price: 458259,
      Count_Of_Buyer: 22,
    },
    {
      city: "JODHPUR",
      State: "Rajasthan",
      Sum_of_price: 156596,
      Count_Of_Buyer: 22,
    },
    {
      city: "Zone 2",
      State: "ANDHRA PRADESH",
      Sum_of_price: 215351,
      Count_Of_Buyer: 21,
    },
    {
      city: "DARBHANGA",
      State: "BIHAR",
      Sum_of_price: 146435,
      Count_Of_Buyer: 21,
    },
    {
      city: "Nagpur",
      State: "MAHARASHTRA",
      Sum_of_price: 103116,
      Count_Of_Buyer: 20,
    },
    {
      city: "Santhal Pargana",
      State: "JHARKHAND",
      Sum_of_price: 295624,
      Count_Of_Buyer: 19,
    },
    {
      city: "Garhwal",
      State: "UTTARAKHAND",
      Sum_of_price: 168161,
      Count_Of_Buyer: 19,
    },
    {
      city: "Zone 4",
      State: "ANDHRA PRADESH",
      Sum_of_price: 246727,
      Count_Of_Buyer: 18,
    },
    {
      city: "SOUTH DELHI",
      State: "DELHI",
      Sum_of_price: 141398,
      Count_Of_Buyer: 18,
    },
    {
      city: "Ujjain",
      State: "MADHYA PRADESH",
      Sum_of_price: 141002,
      Count_Of_Buyer: 18,
    },
    {
      city: "REWARI",
      State: "HARYANA",
      Sum_of_price: 276827,
      Count_Of_Buyer: 17,
    },
    {
      city: "Gya",
      State: "MAHARASHTRA",
      Sum_of_price: 220631,
      Count_Of_Buyer: 17,
    },
    {
      city: "Zone 3",
      State: "ANDHRA PRADESH",
      Sum_of_price: 172924,
      Count_Of_Buyer: 17,
    },
    {
      city: "RAJKOT",
      State: "Gujarat",
      Sum_of_price: 137785,
      Count_Of_Buyer: 17,
    },
    {
      city: "Gwalior",
      State: "MADHYA PRADESH",
      Sum_of_price: 129900,
      Count_Of_Buyer: 17,
    },
    {
      city: "DARJILING",
      State: "WEST BENGAL",
      Sum_of_price: 519900,
      Count_Of_Buyer: 16,
    },
    {
      city: "KOLKATA",
      State: "WEST BENGAL",
      Sum_of_price: 518430,
      Count_Of_Buyer: 16,
    },
    {
      city: "Sambalpur",
      State: "ODISHA",
      Sum_of_price: 372643,
      Count_Of_Buyer: 16,
    },
    {
      city: "CUTTACK",
      State: "ODISHA",
      Sum_of_price: 208921,
      Count_Of_Buyer: 16,
    },
    {
      city: "PUNE",
      State: "MAHARASHTRA",
      Sum_of_price: 166819,
      Count_Of_Buyer: 16,
    },
    {
      city: "MUZAFFARNAGAR",
      State: "UTTAR PRADESH",
      Sum_of_price: 141539,
      Count_Of_Buyer: 16,
    },
    {
      city: "KHORDA",
      State: "ODISHA",
      Sum_of_price: 402159,
      Count_Of_Buyer: 15,
    },
    {
      city: "PURNIA",
      State: "BIHAR",
      Sum_of_price: 401560,
      Count_Of_Buyer: 15,
    },
    {
      city: "Karnal",
      State: "HARYANA",
      Sum_of_price: 371579,
      Count_Of_Buyer: 15,
    },
    {
      city: "NASHIK",
      State: "MAHARASHTRA",
      Sum_of_price: 91497,
      Count_Of_Buyer: 15,
    },
    {
      city: "SUNDERGARH",
      State: "ODISHA",
      Sum_of_price: 354639,
      Count_Of_Buyer: 14,
    },
    {
      city: "Central Kerala",
      State: "KERALA",
      Sum_of_price: 216607,
      Count_Of_Buyer: 14,
    },
    {
      city: "GAYA",
      State: "BIHAR",
      Sum_of_price: 189121,
      Count_Of_Buyer: 14,
    },
    {
      city: "Belagavi",
      State: "KARNATAKA",
      Sum_of_price: 117326,
      Count_Of_Buyer: 14,
    },
    {
      city: "Hubli",
      State: "KARNATAKA",
      Sum_of_price: 110686,
      Count_Of_Buyer: 14,
    },
    {
      city: "HAZARIBAG",
      State: "JHARKHAND",
      Sum_of_price: 537750,
      Count_Of_Buyer: 13,
    },
    {
      city: "Lower Assam",
      State: "ASSAM",
      Sum_of_price: 442479,
      Count_Of_Buyer: 13,
    },
    {
      city: "Chhapra",
      State: "BIHAR",
      Sum_of_price: 300593,
      Count_Of_Buyer: 13,
    },
    {
      city: "KAMRUP",
      State: "ASSAM",
      Sum_of_price: 278281,
      Count_Of_Buyer: 13,
    },
    {
      city: "Malda",
      State: "WEST BENGAL",
      Sum_of_price: 167625,
      Count_Of_Buyer: 13,
    },
    {
      city: "Morbi",
      State: "Gujarat",
      Sum_of_price: 123114,
      Count_Of_Buyer: 13,
    },
    {
      city: "ALLAHABAD",
      State: "UTTAR PRADESH",
      Sum_of_price: 120180,
      Count_Of_Buyer: 12,
    },
    {
      city: "Ambala",
      State: "HARYANA",
      Sum_of_price: 108357,
      Count_Of_Buyer: 12,
    },
    {
      city: "THANE",
      State: "MAHARASHTRA",
      Sum_of_price: 54463,
      Count_Of_Buyer: 12,
    },
    {
      city: "WEST TRIPURA",
      State: "TRIPURA",
      Sum_of_price: 655833,
      Count_Of_Buyer: 11,
    },
    {
      city: "KANCHIPURAM",
      State: "TAMIL NADU",
      Sum_of_price: 337614,
      Count_Of_Buyer: 11,
    },
    {
      city: "SURGUJA",
      State: "CHATTISGARH",
      Sum_of_price: 277818,
      Count_Of_Buyer: 11,
    },
    {
      city: "Kumaoun",
      State: "UTTARAKHAND",
      Sum_of_price: 116659,
      Count_Of_Buyer: 11,
    },
    {
      city: "MUMBAI",
      State: "MAHARASHTRA",
      Sum_of_price: 124952,
      Count_Of_Buyer: 10,
    },
    {
      city: "TIRUVALLUR",
      State: "TAMIL NADU",
      Sum_of_price: 112268,
      Count_Of_Buyer: 10,
    },
    {
      city: "JALANDHAR",
      State: "PUNJAB",
      Sum_of_price: 102434,
      Count_Of_Buyer: 10,
    },
    {
      city: "NORTH 24 PARGANAS",
      State: "WEST BENGAL",
      Sum_of_price: 85910,
      Count_Of_Buyer: 10,
    },
    {
      city: "LUDHIANA",
      State: "PUNJAB",
      Sum_of_price: 76452,
      Count_Of_Buyer: 10,
    },
    {
      city: "TINSUKIA",
      State: "ASSAM",
      Sum_of_price: 376472,
      Count_Of_Buyer: 9,
    },
    {
      city: "JAMMU",
      State: "JAMMU & KASHMIR",
      Sum_of_price: 356013,
      Count_Of_Buyer: 9,
    },
    {
      city: "NAGAON",
      State: "ASSAM",
      Sum_of_price: 308119,
      Count_Of_Buyer: 9,
    },
    {
      city: "Zone 1",
      State: "ANDHRA PRADESH",
      Sum_of_price: 209809,
      Count_Of_Buyer: 9,
    },
    {
      city: "BHARATPUR",
      State: "Rajasthan",
      Sum_of_price: 85952,
      Count_Of_Buyer: 9,
    },
    {
      city: "Kanpur",
      State: "UTTAR PRADESH",
      Sum_of_price: 61428,
      Count_Of_Buyer: 9,
    },
    {
      city: "EAST DELHI",
      State: "DELHI",
      Sum_of_price: 36068,
      Count_Of_Buyer: 9,
    },
    {
      city: "EAST SIKKIM",
      State: "SIKKIM",
      Sum_of_price: 469082,
      Count_Of_Buyer: 8,
    },
    {
      city: "KORIYA",
      State: "CHATTISGARH",
      Sum_of_price: 238486,
      Count_Of_Buyer: 8,
    },
    {
      city: "PAPUM PARE",
      State: "ARUNACHAL PRADESH",
      Sum_of_price: 169807,
      Count_Of_Buyer: 8,
    },
    {
      city: "Charminar",
      State: "TELANGANA",
      Sum_of_price: 114576,
      Count_Of_Buyer: 8,
    },
    {
      city: "Zone-1",
      State: "TAMIL NADU",
      Sum_of_price: 97809,
      Count_Of_Buyer: 8,
    },
    {
      city: "Prayagraj",
      State: "UTTAR PRADESH",
      Sum_of_price: 56957,
      Count_Of_Buyer: 8,
    },
    {
      city: "BANASKANTHA",
      State: "Gujarat",
      Sum_of_price: 54559,
      Count_Of_Buyer: 8,
    },
    {
      city: "INDORE",
      State: "MADHYA PRADESH",
      Sum_of_price: 35656,
      Count_Of_Buyer: 8,
    },
    {
      city: "JORHAT",
      State: "ASSAM",
      Sum_of_price: 286206,
      Count_Of_Buyer: 7,
    },
    {
      city: "SATNA",
      State: "MADHYA PRADESH",
      Sum_of_price: 172456,
      Count_Of_Buyer: 7,
    },
    {
      city: "South Kerala",
      State: "KERALA",
      Sum_of_price: 160292,
      Count_Of_Buyer: 7,
    },
    {
      city: "SARAN",
      State: "BIHAR",
      Sum_of_price: 88536,
      Count_Of_Buyer: 7,
    },
    {
      city: "Berhampur",
      State: "ODISHA",
      Sum_of_price: 88357,
      Count_Of_Buyer: 7,
    },
    {
      city: "GIRIDH",
      State: "JHARKHAND",
      Sum_of_price: 84096,
      Count_Of_Buyer: 7,
    },
    {
      city: "BELLARY",
      State: "KARNATAKA",
      Sum_of_price: 54960,
      Count_Of_Buyer: 7,
    },
    {
      city: "AURANGABAD(BH)",
      State: "BIHAR",
      Sum_of_price: 45177,
      Count_Of_Buyer: 7,
    },
    {
      city: "VADODARA",
      State: "Gujarat",
      Sum_of_price: 40886,
      Count_Of_Buyer: 7,
    },
    {
      city: "SONITPUR",
      State: "ASSAM",
      Sum_of_price: 357038,
      Count_Of_Buyer: 6,
    },
    {
      city: "THANJAVUR",
      State: "TAMIL NADU",
      Sum_of_price: 216366,
      Count_Of_Buyer: 6,
    },
    {
      city: "NORTH GOA",
      State: "GOA",
      Sum_of_price: 114518,
      Count_Of_Buyer: 6,
    },
    {
      city: "SRINAGAR",
      State: "JAMMU & KASHMIR",
      Sum_of_price: 83193,
      Count_Of_Buyer: 6,
    },
    {
      city: "GUNA",
      State: "MADHYA PRADESH",
      Sum_of_price: 57956,
      Count_Of_Buyer: 6,
    },
    {
      city: "DIBRUGARH",
      State: "ASSAM",
      Sum_of_price: 49087,
      Count_Of_Buyer: 6,
    },
    {
      city: "Bhavnagar",
      State: "Gujarat",
      Sum_of_price: 19654,
      Count_Of_Buyer: 6,
    },
    {
      city: "Kolhan",
      State: "JHARKHAND",
      Sum_of_price: 226444,
      Count_Of_Buyer: 5,
    },
    {
      city: "EAST CHAMPARAN",
      State: "BIHAR",
      Sum_of_price: 143483,
      Count_Of_Buyer: 5,
    },
    {
      city: "KARIM NAGAR",
      State: "TELANGANA",
      Sum_of_price: 126095,
      Count_Of_Buyer: 5,
    },
    {
      city: "MARIGAON",
      State: "ASSAM",
      Sum_of_price: 101636,
      Count_Of_Buyer: 5,
    },
    {
      city: "ROHTAS",
      State: "BIHAR",
      Sum_of_price: 100025,
      Count_Of_Buyer: 5,
    },
    {
      city: "GOPALGANJ",
      State: "BIHAR",
      Sum_of_price: 98120,
      Count_Of_Buyer: 5,
    },
    {
      city: "MURSHIDABAD",
      State: "WEST BENGAL",
      Sum_of_price: 85922,
      Count_Of_Buyer: 5,
    },
    {
      city: "Amravati",
      State: "MAHARASHTRA",
      Sum_of_price: 70459,
      Count_Of_Buyer: 5,
    },
    {
      city: "K.V.RANGAREDDY",
      State: "TELANGANA",
      Sum_of_price: 55998,
      Count_Of_Buyer: 5,
    },
    {
      city: "Basara",
      State: "TELANGANA",
      Sum_of_price: 53466,
      Count_Of_Buyer: 5,
    },
    {
      city: "TIRUCHIRAPPALLI",
      State: "TAMIL NADU",
      Sum_of_price: 50439,
      Count_Of_Buyer: 5,
    },
    {
      city: "LATUR",
      State: "MAHARASHTRA",
      Sum_of_price: 43136,
      Count_Of_Buyer: 5,
    },
    {
      city: "SAGAR",
      State: "MADHYA PRADESH",
      Sum_of_price: 39652,
      Count_Of_Buyer: 5,
    },
    {
      city: "BHIWANI",
      State: "HARYANA",
      Sum_of_price: 34888,
      Count_Of_Buyer: 5,
    },
    {
      city: "Shimla",
      State: "HIMACHAL PRADESH",
      Sum_of_price: 32780,
      Count_Of_Buyer: 5,
    },
    {
      city: "KACHCHH",
      State: "Gujarat",
      Sum_of_price: 32234,
      Count_Of_Buyer: 5,
    },
    {
      city: "AKOLA",
      State: "MAHARASHTRA",
      Sum_of_price: 25607,
      Count_Of_Buyer: 5,
    },
    {
      city: "KOLHAPUR",
      State: "MAHARASHTRA",
      Sum_of_price: 23618,
      Count_Of_Buyer: 5,
    },
    {
      city: "SOUTH 24 PARGANAS",
      State: "WEST BENGAL",
      Sum_of_price: 20736,
      Count_Of_Buyer: 5,
    },
    {
      city: "VIZIANAGARAM",
      State: "ANDHRA PRADESH",
      Sum_of_price: 18349,
      Count_Of_Buyer: 5,
    },
    {
      city: "SHAHDARA",
      State: "DELHI",
      Sum_of_price: 289958,
      Count_Of_Buyer: 4,
    },
    {
      city: "KOHIMA",
      State: "NAGALAND",
      Sum_of_price: 179120,
      Count_Of_Buyer: 4,
    },
    {
      city: "BHANDARA",
      State: "MAHARASHTRA",
      Sum_of_price: 144054,
      Count_Of_Buyer: 4,
    },
    {
      city: "MADURAI",
      State: "TAMIL NADU",
      Sum_of_price: 83642,
      Count_Of_Buyer: 4,
    },
    {
      city: "EAST MIDNAPORE",
      State: "WEST BENGAL",
      Sum_of_price: 68836,
      Count_Of_Buyer: 4,
    },
    {
      city: "MADHEPURA",
      State: "BIHAR",
      Sum_of_price: 63152,
      Count_Of_Buyer: 4,
    },
    {
      city: "KAMRUP METROPOLITAN",
      State: "ASSAM",
      Sum_of_price: 59053,
      Count_Of_Buyer: 4,
    },
    {
      city: "ERODE",
      State: "TAMIL NADU",
      Sum_of_price: 58299,
      Count_Of_Buyer: 4,
    },
    {
      city: "Mandi",
      State: "HIMACHAL PRADESH",
      Sum_of_price: 52990,
      Count_Of_Buyer: 4,
    },
    {
      city: "SAMASTIPUR",
      State: "BIHAR",
      Sum_of_price: 48679,
      Count_Of_Buyer: 4,
    },
    {
      city: "RAIPUR",
      State: "CHATTISGARH",
      Sum_of_price: 44354,
      Count_Of_Buyer: 4,
    },
    {
      city: "PUDUKKOTTAI",
      State: "TAMIL NADU",
      Sum_of_price: 41921,
      Count_Of_Buyer: 4,
    },
    {
      city: "Barak Valley",
      State: "ASSAM",
      Sum_of_price: 39363,
      Count_Of_Buyer: 4,
    },
    {
      city: "Mysuru",
      State: "KARNATAKA",
      Sum_of_price: 27737,
      Count_Of_Buyer: 4,
    },
    {
      city: "ANANTHNAG",
      State: "JAMMU & KASHMIR",
      Sum_of_price: 26801,
      Count_Of_Buyer: 4,
    },
    {
      city: "Bastar",
      State: "CHATTISGARH",
      Sum_of_price: 23474,
      Count_Of_Buyer: 4,
    },
    {
      city: "SANGLI",
      State: "MAHARASHTRA",
      Sum_of_price: 19996,
      Count_Of_Buyer: 4,
    },
    {
      city: "NANDED",
      State: "MAHARASHTRA",
      Sum_of_price: 14277,
      Count_Of_Buyer: 4,
    },
    {
      city: "JALPAIGURI",
      State: "WEST BENGAL",
      Sum_of_price: 12193,
      Count_Of_Buyer: 4,
    },
    {
      city: "NORTH WEST DELHI",
      State: "DELHI",
      Sum_of_price: 1607992,
      Count_Of_Buyer: 3,
    },
    {
      city: "WEST MIDNAPORE",
      State: "WEST BENGAL",
      Sum_of_price: 152943,
      Count_Of_Buyer: 3,
    },
    {
      city: "Central Zone B",
      State: "ARUNACHAL PRADESH",
      Sum_of_price: 131756,
      Count_Of_Buyer: 3,
    },
    {
      city: "WEST CHAMPARAN",
      State: "BIHAR",
      Sum_of_price: 100701,
      Count_Of_Buyer: 3,
    },
    {
      city: "AMRITSAR",
      State: "PUNJAB",
      Sum_of_price: 82561,
      Count_Of_Buyer: 3,
    },
    {
      city: "PRATAPGHAR",
      State: "Rajasthan",
      Sum_of_price: 79403,
      Count_Of_Buyer: 3,
    },
    {
      city: "TIRUPPUR",
      State: "TAMIL NADU",
      Sum_of_price: 62497,
      Count_Of_Buyer: 3,
    },
    {
      city: "RAIGARH(MH)",
      State: "MAHARASHTRA",
      Sum_of_price: 56920,
      Count_Of_Buyer: 3,
    },
    {
      city: "SOUTH SIKKIM",
      State: "SIKKIM",
      Sum_of_price: 53742,
      Count_Of_Buyer: 3,
    },
    {
      city: "DINDIGUL",
      State: "TAMIL NADU",
      Sum_of_price: 36336,
      Count_Of_Buyer: 3,
    },
    {
      city: "PATIALA",
      State: "PUNJAB",
      Sum_of_price: 34325,
      Count_Of_Buyer: 3,
    },
    {
      city: "BALESWAR",
      State: "ODISHA",
      Sum_of_price: 33700,
      Count_Of_Buyer: 3,
    },
    {
      city: "East Tripura",
      State: "TRIPURA",
      Sum_of_price: 28570,
      Count_Of_Buyer: 3,
    },
    {
      city: "MAHARAJGANJ",
      State: "UTTAR PRADESH",
      Sum_of_price: 25216,
      Count_Of_Buyer: 3,
    },
    {
      city: "Purulia",
      State: "WEST BENGAL",
      Sum_of_price: 24168,
      Count_Of_Buyer: 3,
    },
    {
      city: "RAICHUR",
      State: "KARNATAKA",
      Sum_of_price: 22714,
      Count_Of_Buyer: 3,
    },
    {
      city: "PONDICHERRY",
      State: "PONDICHERRY",
      Sum_of_price: 19763,
      Count_Of_Buyer: 3,
    },
    {
      city: "WARANGAL",
      State: "TELANGANA",
      Sum_of_price: 19119,
      Count_Of_Buyer: 3,
    },
    {
      city: "RAEBARELI",
      State: "UTTAR PRADESH",
      Sum_of_price: 10558,
      Count_Of_Buyer: 3,
    },
    {
      city: "CHANDRAPUR",
      State: "MAHARASHTRA",
      Sum_of_price: 10368,
      Count_Of_Buyer: 3,
    },
    {
      city: "CENTRAL DELHI",
      State: "DELHI",
      Sum_of_price: 10190,
      Count_Of_Buyer: 3,
    },
    {
      city: "AHMED NAGAR",
      State: "MAHARASHTRA",
      Sum_of_price: 4282,
      Count_Of_Buyer: 3,
    },
    {
      city: "NEW DELHI",
      State: "DELHI",
      Sum_of_price: 404449,
      Count_Of_Buyer: 2,
    },
    {
      city: "Mysore",
      State: "KARNATAKA",
      Sum_of_price: 168662,
      Count_Of_Buyer: 2,
    },
    {
      city: "KASARGOD",
      State: "KERALA",
      Sum_of_price: 79367,
      Count_Of_Buyer: 2,
    },
    {
      city: "KANYAKUMARI",
      State: "TAMIL NADU",
      Sum_of_price: 68643,
      Count_Of_Buyer: 2,
    },
    {
      city: "SAHARSA",
      State: "BIHAR",
      Sum_of_price: 66082,
      Count_Of_Buyer: 2,
    },
    {
      city: "BUDAUN",
      State: "UTTAR PRADESH",
      Sum_of_price: 44722,
      Count_Of_Buyer: 2,
    },
    {
      city: "MAHABUB NAGAR",
      State: "TELANGANA",
      Sum_of_price: 41726,
      Count_Of_Buyer: 2,
    },
    {
      city: "Jamnagar",
      State: "Gujarat",
      Sum_of_price: 36121,
      Count_Of_Buyer: 2,
    },
    {
      city: "EAST GODAVARI",
      State: "ANDHRA PRADESH",
      Sum_of_price: 34868,
      Count_Of_Buyer: 2,
    },
    {
      city: "Fazilka",
      State: "PUNJAB",
      Sum_of_price: 30239,
      Count_Of_Buyer: 2,
    },
    {
      city: "TUTICORIN",
      State: "TAMIL NADU",
      Sum_of_price: 27341,
      Count_Of_Buyer: 2,
    },
    {
      city: "TIRUNELVELI",
      State: "TAMIL NADU",
      Sum_of_price: 23001,
      Count_Of_Buyer: 2,
    },
    {
      city: "KISHANGANJ",
      State: "BIHAR",
      Sum_of_price: 18659,
      Count_Of_Buyer: 2,
    },
    {
      city: "GULBARGA",
      State: "KARNATAKA",
      Sum_of_price: 18417,
      Count_Of_Buyer: 2,
    },
    {
      city: "CUDDALORE",
      State: "TAMIL NADU",
      Sum_of_price: 17327,
      Count_Of_Buyer: 2,
    },
    {
      city: "PATAN",
      State: "Gujarat",
      Sum_of_price: 15074,
      Count_Of_Buyer: 2,
    },
    {
      city: "HINGOLI",
      State: "MAHARASHTRA",
      Sum_of_price: 14822,
      Count_Of_Buyer: 2,
    },
    {
      city: "KANGRA",
      State: "HIMACHAL PRADESH",
      Sum_of_price: 13784,
      Count_Of_Buyer: 2,
    },
    {
      city: "RUPNAGAR",
      State: "PUNJAB",
      Sum_of_price: 13394,
      Count_Of_Buyer: 2,
    },
    {
      city: "GIR SOMNATH",
      State: "Gujarat",
      Sum_of_price: 11896,
      Count_Of_Buyer: 2,
    },
    {
      city: "WEST DELHI",
      State: "DELHI",
      Sum_of_price: 11238,
      Count_Of_Buyer: 2,
    },
    {
      city: "CHANDIGARH",
      State: "CHANDIGARH",
      Sum_of_price: 10879,
      Count_Of_Buyer: 2,
    },
    {
      city: "Arungabad",
      State: "MAHARASHTRA",
      Sum_of_price: 10578,
      Count_Of_Buyer: 2,
    },
    {
      city: "Bhadradri",
      State: "TELANGANA",
      Sum_of_price: 9249,
      Count_Of_Buyer: 2,
    },
    {
      city: "SOUTH GOA",
      State: "GOA",
      Sum_of_price: 8148,
      Count_Of_Buyer: 2,
    },
    {
      city: "SOUTH ANDAMAN",
      State: "ANDAMAN & NICOBAR ISLANDS",
      Sum_of_price: 6928,
      Count_Of_Buyer: 2,
    },
    {
      city: "HISAR",
      State: "HARYANA",
      Sum_of_price: 5510,
      Count_Of_Buyer: 2,
    },
    {
      city: "BUDGAM",
      State: "JAMMU & KASHMIR",
      Sum_of_price: 4844,
      Count_Of_Buyer: 2,
    },
    {
      city: "NORTH DELHI",
      State: "DELHI",
      Sum_of_price: 3262,
      Count_Of_Buyer: 2,
    },
    {
      city: "ROHTAK",
      State: "HARYANA",
      Sum_of_price: 2987,
      Count_Of_Buyer: 2,
    },
    {
      city: "Chitradurga",
      State: "KARNATAKA",
      Sum_of_price: 2016,
      Count_Of_Buyer: 2,
    },
    {
      city: "SHIMOGA",
      State: "KARNATAKA",
      Sum_of_price: 169390,
      Count_Of_Buyer: 1,
    },
    {
      city: "PALAMAU",
      State: "JHARKHAND",
      Sum_of_price: 58294,
      Count_Of_Buyer: 1,
    },
    {
      city: "DODA",
      State: "JAMMU & KASHMIR",
      Sum_of_price: 39710,
      Count_Of_Buyer: 1,
    },
    {
      city: "BARAMULLA",
      State: "JAMMU & KASHMIR",
      Sum_of_price: 21686,
      Count_Of_Buyer: 1,
    },
    {
      city: "NILGIRIS",
      State: "TAMIL NADU",
      Sum_of_price: 21516,
      Count_Of_Buyer: 1,
    },
    {
      city: "AIZAWL",
      State: "MIZORAM",
      Sum_of_price: 19553,
      Count_Of_Buyer: 1,
    },
    {
      city: "Zone-3",
      State: "TAMIL NADU",
      Sum_of_price: 19172,
      Count_Of_Buyer: 1,
    },
    {
      city: "GADCHIROLI",
      State: "MAHARASHTRA",
      Sum_of_price: 15258,
      Count_Of_Buyer: 1,
    },
    {
      city: "NORTH EAST DELHI",
      State: "DELHI",
      Sum_of_price: 14568,
      Count_Of_Buyer: 1,
    },
    {
      city: "IMPHAL WEST",
      State: "MANIPUR",
      Sum_of_price: 14567,
      Count_Of_Buyer: 1,
    },
    {
      city: "ZUNHEBOTTO",
      State: "NAGALAND",
      Sum_of_price: 12320,
      Count_Of_Buyer: 1,
    },
    {
      city: "KULLU",
      State: "HIMACHAL PRADESH",
      Sum_of_price: 12218,
      Count_Of_Buyer: 1,
    },
    {
      city: "KUPWARA",
      State: "JAMMU & KASHMIR",
      Sum_of_price: 11983,
      Count_Of_Buyer: 1,
    },
    {
      city: "FATEHGARH SAHIB",
      State: "PUNJAB",
      Sum_of_price: 11807,
      Count_Of_Buyer: 1,
    },
    {
      city: "FARIDKOT",
      State: "PUNJAB",
      Sum_of_price: 11070,
      Count_Of_Buyer: 1,
    },
    {
      city: "Yadadri",
      State: "TELANGANA",
      Sum_of_price: 10418,
      Count_Of_Buyer: 1,
    },
    {
      city: "OSMANABAD",
      State: "MAHARASHTRA",
      Sum_of_price: 9936,
      Count_Of_Buyer: 1,
    },
    {
      city: "Bilaspur",
      State: "CHATTISGARH",
      Sum_of_price: 9595,
      Count_Of_Buyer: 1,
    },
    {
      city: "GURDASPUR",
      State: "PUNJAB",
      Sum_of_price: 9460,
      Count_Of_Buyer: 1,
    },
    {
      city: "DAVANGARE",
      State: "KARNATAKA",
      Sum_of_price: 9063,
      Count_Of_Buyer: 1,
    },
    {
      city: "LAKHIMPUR",
      State: "ASSAM",
      Sum_of_price: 8569,
      Count_Of_Buyer: 1,
    },
    {
      city: "GOLAGHAT",
      State: "ASSAM",
      Sum_of_price: 8061,
      Count_Of_Buyer: 1,
    },
    {
      city: "SANGRUR",
      State: "PUNJAB",
      Sum_of_price: 7407,
      Count_Of_Buyer: 1,
    },
    {
      city: "DARRANG",
      State: "ASSAM",
      Sum_of_price: 7029,
      Count_Of_Buyer: 1,
    },
    {
      city: "PULWAMA",
      State: "JAMMU & KASHMIR",
      Sum_of_price: 6330,
      Count_Of_Buyer: 1,
    },
    {
      city: "THENI",
      State: "TAMIL NADU",
      Sum_of_price: 6096,
      Count_Of_Buyer: 1,
    },
    {
      city: "JAJAPUR",
      State: "ODISHA",
      Sum_of_price: 6080,
      Count_Of_Buyer: 1,
    },
    {
      city: "Manglore",
      State: "KARNATAKA",
      Sum_of_price: 5478,
      Count_Of_Buyer: 1,
    },
    {
      city: "FARRUKHABAD",
      State: "UTTAR PRADESH",
      Sum_of_price: 5425,
      Count_Of_Buyer: 1,
    },
    {
      city: "BIRBHUM",
      State: "WEST BENGAL",
      Sum_of_price: 5260,
      Count_Of_Buyer: 1,
    },
    {
      city: "KAPURTHALA",
      State: "PUNJAB",
      Sum_of_price: 4925,
      Count_Of_Buyer: 1,
    },
    {
      city: "KAIMUR (BHABUA)",
      State: "BIHAR",
      Sum_of_price: 4863,
      Count_Of_Buyer: 1,
    },
    {
      city: "TIRUVANNAMALAI",
      State: "TAMIL NADU",
      Sum_of_price: 4590,
      Count_Of_Buyer: 1,
    },
    {
      city: "Zone-5",
      State: "TAMIL NADU",
      Sum_of_price: 4526,
      Count_Of_Buyer: 1,
    },
    {
      city: "KARUR",
      State: "TAMIL NADU",
      Sum_of_price: 4063,
      Count_Of_Buyer: 1,
    },
    {
      city: "DHULE",
      State: "MAHARASHTRA",
      Sum_of_price: 4022,
      Count_Of_Buyer: 1,
    },
    {
      city: "DURG",
      State: "CHATTISGARH",
      Sum_of_price: 4012,
      Count_Of_Buyer: 1,
    },
    {
      city: "NAWANSHAHR",
      State: "PUNJAB",
      Sum_of_price: 3867,
      Count_Of_Buyer: 1,
    },
    {
      city: "DEVBHOOMI DWERKA",
      State: "Gujarat",
      Sum_of_price: 3420,
      Count_Of_Buyer: 1,
    },
    {
      city: "Western Zone",
      State: "ARUNACHAL PRADESH",
      Sum_of_price: 3222,
      Count_Of_Buyer: 1,
    },
    {
      city: "SITAMARHI",
      State: "BIHAR",
      Sum_of_price: 3088,
      Count_Of_Buyer: 1,
    },
    {
      city: "Telengana",
      State: "ANDHRA PRADESH",
      Sum_of_price: 2899,
      Count_Of_Buyer: 1,
    },
    {
      city: "SIRMAUR",
      State: "HIMACHAL PRADESH",
      Sum_of_price: 2791,
      Count_Of_Buyer: 1,
    },
    {
      city: "BULANDSHAHR",
      State: "UTTAR PRADESH",
      Sum_of_price: 2566,
      Count_Of_Buyer: 1,
    },
    {
      city: "Davanagere",
      State: "KARNATAKA",
      Sum_of_price: 2461,
      Count_Of_Buyer: 1,
    },
    {
      city: "UDHAMPUR",
      State: "JAMMU & KASHMIR",
      Sum_of_price: 2219,
      Count_Of_Buyer: 1,
    },
    {
      city: "SABARKANTHA",
      State: "Gujarat",
      Sum_of_price: 2189,
      Count_Of_Buyer: 1,
    },
    {
      city: "SIVAGANGA",
      State: "TAMIL NADU",
      Sum_of_price: 2141,
      Count_Of_Buyer: 1,
    },
    {
      city: "TUENSANG",
      State: "NAGALAND",
      Sum_of_price: 2100,
      Count_Of_Buyer: 1,
    },
    {
      city: "BARNALA",
      State: "PUNJAB",
      Sum_of_price: 2025,
      Count_Of_Buyer: 1,
    },
    {
      city: "JALGAON",
      State: "MAHARASHTRA",
      Sum_of_price: 1667,
      Count_Of_Buyer: 1,
    },
    {
      city: "Pathankot",
      State: "PUNJAB",
      Sum_of_price: 1654,
      Count_Of_Buyer: 1,
    },
    {
      city: "HOSHIARPUR",
      State: "PUNJAB",
      Sum_of_price: 1582,
      Count_Of_Buyer: 1,
    },
    {
      city: "JYOTIBA PHULE NAGAR",
      State: "UTTAR PRADESH",
      Sum_of_price: 1248,
      Count_Of_Buyer: 1,
    },
    {
      city: "Zone-2",
      State: "TAMIL NADU",
      Sum_of_price: 1117,
      Count_Of_Buyer: 1,
    },
    {
      city: "RATLAM",
      State: "MADHYA PRADESH",
      Sum_of_price: 970,
      Count_Of_Buyer: 1,
    },
    {
      city: "KENDRAPARA",
      State: "ODISHA",
      Sum_of_price: 734,
      Count_Of_Buyer: 1,
    },
    {
      city: "Rajanna",
      State: "TELANGANA",
      Sum_of_price: 718,
      Count_Of_Buyer: 1,
    },
    {
      city: "SOUTH WEST DELHI",
      State: "DELHI",
      Sum_of_price: 490,
      Count_Of_Buyer: 1,
    },
  ];

  return (
    <SubCard title={"Cluster wise report"}>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer component={Paper} sx={{ maxHeight: '80vh' }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ minWidth: 650, maxHeight: '80vh' }}
          >
            <TableHead>
              <TableRow>
                <TableCell>cluster</TableCell>

                <TableCell>State</TableCell>
                <TableCell>Total payable amount</TableCell>
                <TableCell>Count of Buyers</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderList &&
                orderList.map((order) => (
                  <TableRow
                    key={order.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{order.city}</TableCell>
                    <TableCell>{order.State}</TableCell>
                    <TableCell>{order?.Sum_of_price}</TableCell>
                    <TableCell>{order?.Count_Of_Buyer}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </SubCard>
  );
}

export default LorealCityWiseData;
