import React, { lazy } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// project imports
import MainLayout from "./../layout/MainLayout";
import Loadable from "../ui-component/Loadable";
import AuthGuard from "./../utils/route-guard/AuthGuard";
import CustomerHome from "../menu-items/CustomerHome/CustomerHome";
import Forbidden from "../menu-items/Forbidden";
import NotFound from "../menu-items/NotFound";
import { MENU_OPEN } from "../store/actions";
import { useEffect } from "react";
import { routeList, routeTitles } from "./routeTitles";
import GoogleNotification from "../GoogleNotification";
import { routePaths, skipPath } from "./routePaths";
import { logFirebaseEvent } from "../LogFirebaseEvent";
import ContactSupport1 from "../views/pages/Others/ContactSupport1";
import SubscriptionDetailPage from "../menu-items/Subscription/SubscriptionDetailPage";
import { LorealBrand } from "../menu-items/LorealBrand";
import LorealCityWiseData from "../menu-items/LorealBrand/LorealCityWiseData";
import RegionWIseData from "../menu-items/LorealBrand/RegionWIseData";
import ProductWiseReport from "../menu-items/LorealBrand/ProductWiseReport";
// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("../views/dashboard/Default"))
);
const NewDashboard = Loadable(
  lazy(() => import("../views/NewDashboard/Default"))
);

//New Home Routes
const AllSalonList = Loadable(
  lazy(() => import("../menu-items/DevArea/AllSalonList"))
);
const ServiceHome = Loadable(
  lazy(() => import("../menu-items/Services/ServiceHome"))
);
const ProductsHome = Loadable(
  lazy(() => import("../menu-items/Products/ProductsHome"))
);
const SMSPromotions = Loadable(
  lazy(() => import("../menu-items/Promotions/SMSPromotions"))
);
const WhatsappPromotions = Loadable(
  lazy(() => import("../menu-items/Promotions/WhatsappPromotions"))
);
// const NewPromotion = Loadable(lazy(() => import('../menu-items/Promotions/NewPromotion')));

const SalesReports = Loadable(
  lazy(() => import("../menu-items/Reports/SalesReport"))
);

// Appointment routes
// const AppointmentHome = Loadable(lazy(() => import('../menu-items/Home/AppointmentHome')));
const Appointments = Loadable(
  lazy(() => import("../menu-items/Appointments/CalendarHome"))
);

// Employee Related Routes
const EmployeeHome = Loadable(
  lazy(() => import("../menu-items/EmployeeHome/EmployeeHome"))
);
const EmployeeProfile = Loadable(
  lazy(() => import("../menu-items/EmployeeHome/EmployeeProfile"))
);
const CustomerProfile = Loadable(
  lazy(() => import("../menu-items/CustomerHome/CustomerProfile"))
);
const EmployeeAccess = Loadable(
  lazy(() => import("../menu-items/EmployeeAccess/EmployeeSection"))
);
const EmpAppointmentHistory = Loadable(
  lazy(() =>
    import("../menu-items/EmployeeAccess/Employee/EmpAppointmentHistory")
  )
);
const EmpAttendance = Loadable(
  lazy(() => import("../menu-items/EmployeeAccess/Employee/EmpAttendance"))
);
const AppointmentsList = Loadable(
  lazy(() => import("../menu-items/Appointments/AppointmentsList"))
);
const AppointmentDetails = Loadable(
  lazy(() => import("../menu-items/Appointments/AppointmentDetails"))
);
const AppointmentDetailById = Loadable(
  lazy(() => import("../menu-items/Appointments/AppointmentDetailById"))
);

const DetailedSalonList = Loadable(
  lazy(() => import("../menu-items/DevArea/DetailedSalonList"))
);

const AllSubscriptions = Loadable(
  lazy(() => import("../menu-items/Subscription/AllSubscriptions"))
);
const ManageUsers = Loadable(
  lazy(() => import("../menu-items/Subscription/ManageUsers"))
);

// Profile Route
const MyProfile = Loadable(lazy(() => import("../menu-items/Profile/index")));
const SalonProfile = Loadable(
  lazy(() => import("../menu-items/Salon/SalonProfile"))
);

// customer List route
const CustomerList = Loadable(
  lazy(() => import("../menu-items/CustomerHome/CustomerList"))
);
const ChangePassword = Loadable(
  lazy(() => import("../routes/ManagePassword/ChangePassword"))
);

// const ShoppingHomepage = Loadable(lazy(() => import('../menu-items/Shopping/ShoppingHomepage')));
const UserCartPage = Loadable(
  lazy(() => import("../menu-items/Shopping/UserCartPage"))
);
const OrderSuccessful = Loadable(
  lazy(() => import("../menu-items/Shopping/OrderSuccessfull"))
);
const WhatsappChat = Loadable(lazy(() => import("../menu-items/WhatsappChat")));

// ONDC ROutes

const OndcProducts = Loadable(lazy(() => import("../menu-items/ONDC/Inventory/OndcProducts")));
const LedgerHistoryOfProduct = Loadable(lazy(() => import("../menu-items/ONDC/Inventory/LedgerHistoryOfProduct")));
const OndcSellerProfile = Loadable(lazy(() => import("../menu-items/ONDC/Seller/OndcSellerProfile")));
const OndcOrderList = Loadable(lazy(() => import("../menu-items/ONDC/OndcOrders/OndcOrderList")));
const OndcOrderDetails = Loadable(lazy(() => import("../menu-items/ONDC/OndcOrders/OndcOrderDetail")));
const ComplainsList = Loadable(lazy(() => import("../menu-items/ONDC/Complains/ComplainsList")));
const ComplaintDetailsView = Loadable(lazy(() => import("../menu-items/ONDC/Complains/ComplaintDetailsView")));
const SettlementsDashboard = Loadable(lazy(() => import("../menu-items/ONDC/Settlement/SettlementsDashboard")));

// Brand Reports
const BrandReports = Loadable(lazy(() => import("../menu-items/BrandReports")));


//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
  const location = useLocation();
  const history = useHistory();
  const userInfo = useSelector((state) => state?.userInfo?.userData);
  const userRole = userInfo?.role;
  // console.log("userInfo in mainroutes", userInfo)

  const dispatch = useDispatch();

  const handleMenuOpen = (selectedRoute) => {
    if (selectedRoute != null && routeList[selectedRoute] != null) {
      dispatch({
        type: MENU_OPEN,
        id: routeList[selectedRoute],
      });
    }
  };
  useEffect(() => {
    let currentPath = window.location.pathname;
    handleMenuOpen(currentPath);
    if (currentPath) {
      if (currentPath.includes("/AppointmentDetailById")) {
        document.title = routeTitles[currentPath] ?? "Appointment Receipt ";
      } else if (currentPath.includes("/EmployeeProfile")) {
        document.title = routeTitles[currentPath] ?? "Employee Profile";
      } else {
        document.title = routeTitles[currentPath] ?? "Dashboard";
      }
    }
  }, [window.location.pathname]);

  useEffect(() => {
    let currentPath = window.location.pathname;
    if (
      routePaths.includes(currentPath) ||
      routePaths.includes(currentPath.slice(4))
    ) {
    } else {
      let splited = currentPath.split("/");
      if (skipPath.includes(splited[1])) {
      } else {
        history.push("/not-found");
      }
    }
    logFirebaseEvent("user_login");
  }, []);
  useEffect(() => {
    if (userInfo?.phoneNumber && window?.clarity) {
      window.clarity("identify", userInfo.phoneNumber);
      window.clarity("set", "customer_number", userInfo?.phoneNumber);
    }
  }, []);

  return (
    <Route path={routePaths}>
      <MainLayout>
        {window.location.pathname == "/whatsapp-chat" ? null : (
          <div
            style={{ position: "fixed", zIndex: 1300, bottom: 5, right: 10 }}
          >
            <ContactSupport1 />
          </div>
        )}
        <Switch location={location} key={location.pathname}>
          <AuthGuard>
            <Route path="/dashboard/default" component={DashboardDefault} />
            <Route path="/not-found" component={NotFound} />
            {/* <Route path="/new-promotion" component={NewPromotion} /> */}
            <Route path="/whatsapp-chat" component={WhatsappChat} />
            <Route path="/sales-report" component={SalesReports} />

            <Route path="/all-subscriptions" component={AllSubscriptions} />
            <Route path="/subscriptions-users" component={ManageUsers} />
            <Route
              path="/subscription-detail"
              component={SubscriptionDetailPage}
            />

            <Route
              path="/dev-area/detailed-salons-list"
              component={DetailedSalonList}
            />
            {/* NEW DASHBOARD LINKS HERE */}

            {/* employee related routes */}
            <Route
              path="/EmployeeProfile/:employeeId"
              component={EmployeeProfile}
            />

            {/* appointment related routes */}
            {userRole == 1 ? (
              <>
                <Route path="/dev-area/allsalons" component={AllSalonList} />
              </>
            ) : (
              <Route path="/dev-area/allsalons" component={NewDashboard} />
            )}
            {userRole == 1 || userRole == 2 ? (
              <>
                <Route path="/dashboard" component={NewDashboard} />
                <Route path="/serviceHome" component={ServiceHome} />
                <Route path="/products" component={ProductsHome} />
                <Route path="/sms-promotions" component={SMSPromotions} />
                <Route
                  path="/whatsapp-promotions"
                  component={WhatsappPromotions}
                />
                <Route path="/appointments" component={Appointments} />
                <Route path="/employeeSection" component={Appointments} />
                {/* customer list route */}
                <Route path="/customerList" component={CustomerList} />
                <Route path="/customerHome" component={CustomerHome} />
                {/* employee related routes */}
                <Route path="/employeeAppointments" component={Appointments} />
                <Route path="/AppointmentsList" component={AppointmentsList} />
                <Route path="/employeeAttendance" component={Appointments} />
                <Route path="/employeeHome" component={EmployeeHome} />
                <Route path="/salon-profile" component={SalonProfile} />
                <Route
                  path="/AppointmentDetails/:appointmentId"
                  component={AppointmentDetails}
                />
                <Route
                  path="/AppointmentDetailById/:appointmentId"
                  component={AppointmentDetailById}
                />

                {/* <Route path="/shopping" component={ShoppingHomepage} /> */}
              </>
            ) : (
              <>
                {/* <Route path="/appointments" component={EmployeeAccess} /> */}
                <Route path="/dashboard" component={Appointments} />

                <Route path="/products" component={NotFound} />
                <Route path="/appointments" component={Appointments} />
                <Route path="/employeeSection" component={EmployeeAccess} />
                <Route
                  path="/employeeAppointments"
                  component={EmpAppointmentHistory}
                />
                <Route path="/employeeAttendance" component={EmpAttendance} />
                <Route path="/serviceHome" component={NotFound} />
                <Route path="/sms-promotions" component={NotFound} />
                <Route path="/whatsapp-promotions" component={NotFound} />
                <Route path="/AppointmentsList" component={Appointments} />
                <Route
                  path="/AppointmentDetails/:appointmentId"
                  component={AppointmentDetails}
                />
                <Route
                  path="/AppointmentDetailById/:appointmentId"
                  component={AppointmentDetailById}
                />
                {/* <Route path="/print-receipt/:appointmentId" component={AppointmentReceipt} /> */}
                <Route path="/salon-profile" component={NotFound} />

                {/* customer list route */}
                <Route path="/customerList" component={NotFound} />
                <Route path="/customerHome" component={NotFound} />
                {/* employee related routes */}
                <Route path="/employeeHome" component={NotFound} />
                <Route path="/shopping" component={NotFound} />
                <Route path="/my-cart" component={NotFound} />
                <Route path="/order-successful/:orderId" component={NotFound} />
              </>
            )}

            {/* ONDC ROUTES */}
            <Route path='/ondc-products' component={OndcProducts} />
            <Route path='/ondc-product-ledger/:productId' component={LedgerHistoryOfProduct} />
            <Route path='/my-profile' component={OndcSellerProfile} />
            <Route path='/ondc-orders-list' component={OndcOrderList} />
            <Route path='/ondc-orders-Details/:orderId' component={OndcOrderDetails} />
            <Route path='/ondc-complain' component={ComplainsList} />
            <Route path='/ondc-complain-details/:complainId' component={ComplaintDetailsView} />
            <Route path='/ondc/my-settlement-dashboard' component={SettlementsDashboard} />


            {/* myprofile route */}
            <Route path="/account/myprofile" component={MyProfile} />

            {/* Brand Report */}
            <Route path='/reports/brandreport' component={BrandReports} />

            <Route
              path="/customerProfile/:customerId"
              component={CustomerProfile}
            />
            <Route path="/profile/ChangePassword" component={ChangePassword} />
            {/* Loreal brand  */}
            <Route path='/clusterwisereport' component={LorealCityWiseData} />
            <Route path='/regionwisedata' component={RegionWIseData} />
            <Route path='/productwisereport' component={ProductWiseReport} />
          </AuthGuard>
        </Switch>
      </MainLayout>
    </Route>
  );
};

export default MainRoutes;
