"use client";

import { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Skeleton,
  Box,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import FilterListIcon from "@mui/icons-material/FilterList";
import axios from "axios";
import SubCard from "../../ui-component/cards/SubCard";
// Static data for each region

const SkeletonBox = () => {
  return (
    <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}} >
      <Skeleton height={550} width={200} animation="wave" />
      <Skeleton height={550} width={200} animation="wave" />
      <Skeleton height={550} width={200} animation="wave" />
    </div>
  );
};
export default function ProductWiseReport() {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataWithCategory,setDataWithCategory] = useState([])

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  // Reset filters to show all data
  const showAllData = (data) => {
    setSelectedCategory(null);
    let flatData = data.flatMap((cate)=>cate.groupedProduct)
    let sortData = flatData.sort((a,b)=>b.quantity-a.quantity)
    setFilteredData(sortData)
  };

  const processLorealData = (data) => {
    return data
    .filter(item => item.Brand === 'Loreal')
    .reduce((acc, item) => {
      let category = acc.find(c => c.category === item.CATEGORY);
      if (!category) {
        category = { category: item.CATEGORY, products: [], sum: 0, groupedProduct: [] };
        acc.push(category);
      }
      category.products.push(item);
      category.sum += item["TotalPayableAmount"] || 0;
      
      let productGroup = category.groupedProduct.find(p => p.name === item.Name);
      if (!productGroup) {
        productGroup = { name: item.Name, quantity: 0 };
        category.groupedProduct.push(productGroup);
      }
      productGroup.quantity += item.Quantity || 0;
      return acc;
    }, []);
  };

const handleBarClick = (data) => {
  if (loading) return;
  setSelectedCategory(data.activeLabel);
  let filterCate = dataWithCategory.filter((item) => item.category == data.activeLabel)[0].groupedProduct
  let sortData = filterCate.sort((a,b)=>b.quantity-a.quantity)
  setFilteredData(sortData);
};



  const getData = () => {
    axios
      .get('https://prelive.buyume.io' + "/testing/getLorealZoneWiseData")
      .then((res) => {
        console.log("res of data", res.data);
        if (res.data.success) {
          const dataa = processLorealData(res.data.data)
          showAllData(dataa)
          setDataWithCategory(dataa)
          setLoading(false)
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <SubCard className="p-4">
      <Typography variant="h4" component="h1" gutterBottom>
        Product wise report
      </Typography>

      <Grid container spacing={3}>
        {/* South Data Card */}
        <Grid item xs={12} md={12}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
            }}
          >
            <div className="flex justify-between items-center mb-2">
              <Typography
                variant="h6"
                color="#4fc3f7"
                sx={{ display: "flex", alignItems: "center" }}
              >Category
              </Typography>
              {/* <Button variant="outlined" size="small" sx={{ color: "#4fc3f7", borderColor: "#4fc3f7" }}>
                Show Data
              </Button> */}
            </div>
            <ResponsiveContainer width="100%" height={450}>
              {loading ? (
                <SkeletonBox />
              ) : (
                <BarChart data={dataWithCategory} barCategoryGap="20%" onClick={(e)=>handleBarClick(e)}>
                <XAxis dataKey="category" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="sum" fill="#8884d8" strokeWidthx={2} />
              </BarChart>
              )}
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>

      {/* Data Table */}
      <Paper elevation={3} sx={{ mt: 3, p: 2 }}>
        <div className="flex justify-between items-center mb-3">
          <Typography variant="h6">
            {selectedCategory
              ? `${selectedCategory} Category Products`
              : "All Category Products"}
          </Typography>
          {selectedCategory && (
            <Button
              variant="outlined"
              startIcon={<FilterListIcon />}
              onClick={()=>  showAllData(dataWithCategory)}
            >
              Show All Data
            </Button>
          )}
        </div>
        <TableContainer sx={{ maxHeight: "80vh" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Quantity</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData &&
                filteredData.length > 0 &&
                filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((order) => (
                    <TableRow key={order.name}>
                      <TableCell>{order.name}</TableCell>
                      <TableCell>{order.quantity}</TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 250, 1000]}
          component="div"
          count={filteredData.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </SubCard>
  );
}
