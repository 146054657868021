export const routePaths = [
  "/dashboard/default",
  "/not-found",
  // '/dashboard/analytics',

  // New dashboard

  "/dashboard",
  // '/dashboard',
  "/serviceHome",
  "/sms-promotions",
  "/whatsapp-promotions",
  "/products",
  "/dev-area/detailed-salons-list",

  // appointment related routes
  // '/appointmentHome',
  "/appointments",

  "/shopping",
  "/my-cart",

  "/all-subscriptions",
  "/subscriptions-users",

  // myprofile route
  "/account/myprofile",
  "/salon-profile",

  // employee related routes
  "/employeeHome",
  "/EmployeeProfile/:employeeId",
  "/employeeSection",
  // customer list route
  "/customerList",
  "/customerHome",
  "/customerProfile/:customerId",
  "/profile/ChangePassword",
  "/employeeAppointments",
  "/employeeAttendance",
  "/AppointmentsList",
  "/AppointmentDetails/:appointmentId",
  "/AppointmentDetailById/:appointmentId",
  "/print-receipt/:appointmentId",
  "/order-successful",

  "/whatsapp-chat",

  // New dashboard  ends

  "/widget/statistics",
  "/widget/data",
  "/widget/chart",
  "/widget/chart2",

  "/user/social-profile/:tab",
  "/user/account-profile/profile1",
  "/user/account-profile/profile2",
  "/user/account-profile/profile3",

  "/user/card/card1",
  "/user/card/card2",
  "/user/card/card3",
  "/user/List/list1",
  "/user/List/list2",

  "/customer/customer-list",
  "/customer/order-list",
  "/customer/order-details",
  "/customer/product",
  "/customer/product-review",

  "/app/chat",
  "/app/mail",
  "/app/calendar",
  "/app/contact/c-card",
  "/app/contact/c-list",

  "/components/text-field",
  "/components/button",
  "/components/checkbox",
  "/components/autoComplete",
  "/components/slider",
  "/components/radio",
  "/components/switch",
  "/components/date-time",

  "/forms/frm-autocomplete",
  "/forms/frm-mask",
  "/forms/frm-clipboard",
  "/forms/frm-recaptcha",
  "/forms/frm-wysiwug",
  "/forms/frm-modal",
  "/forms/frm-tooltip",

  "/forms/layouts/layouts",
  "/forms/layouts/multicolumnforms",
  "/forms/layouts/actionbar",
  "/forms/layouts/stickyactionbar",

  "/tables/tbl-basic",
  "/tables/tbl-dense",
  "/tables/tbl-enhanced",
  "/tables/tbl-data",
  "/tables/tbl-customized",
  "/tables/tbl-sticky-header",
  "/tables/tbl-collapse",

  "/chart/apexchart",
  "/forms/forms-validation",
  "/forms/forms-wizard",

  "/basic/accordion",
  "/basic/avatar",
  "/basic/badges",
  "/basic/breadcrumb",
  "/basic/cards",
  "/basic/chip",
  "/basic/list",
  "/basic/tabs",

  "/advance/alert",
  "/advance/dialog",
  "/advance/pagination",
  "/advance/progress",
  "/advance/rating",
  "/advance/snackbar",
  "/advance/skeleton",
  "/advance/speeddial",
  "/advance/timeline",
  "/advance/toggle-button",
  "/advance/treeview",

  "/pages/price/price1",
  "/pages/price/price2",

  "/utils/util-typography",
  "/utils/util-color",
  "/utils/util-shadow",
  "/icons/tabler-icons",
  "/icons/material-icons",
  "/utils/util-animation",
  "/utils/util-grid",

  "/sample-page",
  "/new-promotion",
  // '/sales-report',
  "/dev-area/allsalons",

  // ONDC Products
  '/ondc-products',
  '/ondc-product-ledger/:productId',
  '/my-profile',
  '/ondc-orders-list',
  '/ondc-orders-Details/:orderId',
  '/ondc-complain',
  '/ondc-complain-details/:complainId',
  '/ondc/my-settlement-dashboard',

  // Loral Brand data
  '/clusterwisereport',
  '/regionwisedata',
  '/productwisereport'
];

export let skipPath = [
  "EmployeeProfile",
  "AppointmentDetailById",
  "customerProfile",
  "AppointmentDetails",
  "order-successful",
  "print-receipt",
  'ondc-product-ledger',
  'ondc-orders-Details',
  'ondc-complain-details'
];
